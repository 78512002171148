import React from 'react';
import { formatDuration } from '../../../src/js/lib/duration';
import BarLoader from 'react-spinners/BarLoader';
import Pagination from '../../Pagination';
import apiFetch from '../../../src/js/fetch';
import Container from '../../ContentContainer';
import IcFilter from '../../svg/IcFilter';

class DistanceLeaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contestId: props.contest_id,
      challengeType: props.distance_challenge_type,
      userId: props.user_id,
      contestants: [],
      loading: true,

      numPages: 1,
      currentPage: 1,

      orderBy: 'total_distance_in_meters',
      filterTimeFrom: '',
      filterTimeTo: '',
      filterGender: 'all',
      openFilter: false,
    };
  }

  componentDidMount() {
    this.fetchContestants(this.state.currentPage);

    window.reloadLeaderboard = this.reloadLeaderboard;

    document.body.addEventListener('click', this.handleClickOutsideFilter);
  }

  reloadLeaderboard = () => {
    this.setState({ currentPage: 1, contestants: [], loading: true }, () => {
      this.fetchContestants(this.state.currentPage);
    });
  };

  componentWillUnmount() {
    window.reloadLeaderboard = null;
    document.body.removeEventListener('click', this.handleClickOutsideFilter);
  }

  onPaginate = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchContestants(page);
    });
  };

  fetchContestants = async (page) => {
    this.setState({ loading: true });

    const { contestId, orderBy, filterTimeFrom, filterTimeTo, filterGender } =
      this.state;
    try {
      const data = await apiFetch(`/api/challenges/leaderboard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contest_id: contestId,
          page: page,
          filter: {
            gender: filterGender,
            time_from: filterTimeFrom,
            time_to: filterTimeTo,
          },
          order: {
            field: orderBy,
          },
        }),
      });

      console.log({ data });

      this.setState({
        contestants: data.contestants,
        numPages: data.total_pages,
      });
    } catch (e) {
      console.log({ e });
      const err = e.json ? await e.json() : null;
      console.log({ err });

      this.setState({
        loading: false,
        error:
          (err && err.error_message) ||
          'Something went wrong. Please try again later.',
      });
    }
    this.setState({ loading: false });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.fetchContestants(this.state.currentPage);
    });
  };

  toggleFilter = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  handleClickOutsideFilter = (e) => {
    if (
      this.state.openFilter &&
      !e.target.closest('.challenge-leaderboard-filter') &&
      !e.target.closest('.filter-trigger')
    ) {
      this.setState({ openFilter: false });
    }
  };

  render() {
    const {
      contestants,
      numPages,
      currentPage,
      userId,
      challengeType,
      orderBy,
      filterTimeTo,
      filterTimeFrom,
      filterGender,
      openFilter,
    } = this.state;

    return (
      <>
        <div className='flex flex-wrap mb-4 items-end relative'>
          <div className={`mr-4`}>
            <label
              className='mini-label font-medium block mb-2'
              htmlFor='orderby'
            >
              Sort by
            </label>
            <select
              name='orderBy'
              id='orderby'
              onChange={this.onChange}
              value={orderBy}
              className='block bg-background-quaternary'
            >
              <option value='total_distance_in_meters'>Distance</option>
              <option value='total_elevation_gain_in_meters'>Elevation</option>
              <option value='duration'>Time</option>
              <option value='longest_activity_in_meters'>
                Longest activity
              </option>
            </select>
          </div>
          <div className='lg:relative'>
            <button
              onClick={this.toggleFilter}
              className='filter-trigger button bordered'
            >
              <span className='svg-fill pr-2'>
                <IcFilter />
              </span>{' '}
              Filter
            </button>
            <div
              className={`challenge-leaderboard-filter ${
                openFilter ? 'block' : 'hidden'
              } absolute top-full left-0 bg-background-quaternary border border-border rounded-lg p-6 z-10 mt-4 w-full max-w-md md:w-[24rem] lg:w-[28rem]`}
            >
              <div className='w-full mb-6'>
                <label
                  htmlFor='filterGender'
                  className='mini-label font-medium mb-2'
                >
                  Gender
                </label>
                <select
                  name='filterGender'
                  id='filterGender'
                  onChange={this.onChange}
                  value={filterGender}
                  className='block bg-background-quaternary w-full'
                >
                  <option value='all'>All</option>
                  <option value='female'>Female</option>
                  <option value='male'>Male</option>
                </select>
              </div>
              <div className='flex'>
                <div className='flex-1 mr-4'>
                  <label
                    className='mini-label font-medium block mb-2'
                    htmlFor='filterTimeFrom'
                  >
                    From
                  </label>
                  <input
                    type='date'
                    name='filterTimeFrom'
                    id='filterTimeFrom'
                    onChange={this.onChange}
                    value={filterTimeFrom}
                    className='block w-full input'
                    max={new Date().toISOString().split('T')[0]}
                  />
                </div>
                <div className='flex-1'>
                  <label
                    className='mini-label font-medium block mb-2'
                    htmlFor='filterTimeTo'
                  >
                    To
                  </label>
                  <input
                    type='date'
                    name='filterTimeTo'
                    id='filterTimeTo'
                    onChange={this.onChange}
                    value={filterTimeTo}
                    className='block w-full input'
                    min={filterTimeFrom || ''}
                    max={new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Container>
          <h2 className='mini-label font-medium'>Leaderboard</h2>
          <div className='w-full overflow-x-auto challenge-leaderboard'>
            <table className='w-full border-spacing-y-2 border-separate'>
              <thead className=''>
                <tr className=''>
                  <th className=''>#</th>
                  <th className=''>Challenger</th>
                  <th className=''>
                    Longest{' '}
                    {challengeType === 'RUNNING'
                      ? 'run'
                      : challengeType === 'CYCLING'
                      ? 'ride'
                      : 'activity'}
                  </th>
                  {challengeType === 'RUNNING' && (
                    <th className=''>Avg. pace</th>
                  )}
                  <th className=''>Elevation</th>
                  <th className=''>Time</th>
                  <th className=''>Total kms</th>
                </tr>
              </thead>
              <tbody>
                <tr className='h-2' data-spacer></tr>
                {contestants.map((contestant, i) => (
                  <tr
                    key={contestant.id}
                    className={`${
                      contestant.user_id === userId
                        ? 'current-user font-medium'
                        : ''
                    }`}
                  >
                    <td className=''>
                      {contestant.filtered
                        ? contestant[orderBy] && contestant[orderBy] > 0
                          ? `${i + 1}.`
                          : '-'
                        : contestant?.position && contestant.position > 0
                        ? `${contestant.position}.`
                        : '-'}
                      {/* {contestant?.position && contestant.position > 0
                        ? `${contestant.position}.`
                        : '-'} */}
                    </td>
                    <td className=''>{contestant.user.name}</td>
                    <td className=''>
                      {contestant.longest_activity_in_meters
                        ? (
                            contestant.longest_activity_in_meters / 1000
                          ).toFixed(2)
                        : '0'}{' '}
                      km
                    </td>
                    {challengeType === 'RUNNING' && (
                      <td className=''>
                        {contestant.avg_pace
                          ? formatDuration(contestant.avg_pace)
                          : '00:00'}{' '}
                        / km
                      </td>
                    )}
                    <td className=''>
                      {contestant.total_elevation_gain_in_meters
                        ? contestant.total_elevation_gain_in_meters.toFixed(0)
                        : '0'}{' '}
                      m
                    </td>
                    <td className=''>
                      {contestant.duration
                        ? formatDuration(contestant.duration)
                        : '00:00'}
                    </td>
                    <td className=''>
                      {contestant.total_distance_in_meters
                        ? (contestant.total_distance_in_meters / 1000).toFixed(
                            2
                          )
                        : '0'}{' '}
                      km
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {this.state.loading ? <BarLoader /> : null}

            <Pagination
              numPages={numPages}
              currentPage={currentPage}
              onPaginate={this.onPaginate}
            />
          </div>
        </Container>
      </>
    );
  }
}
export default DistanceLeaderboard;
